<template>
  <div>
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-item-list'"
        :title="'Create item'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
      <v-form
        ref="form"
        lazy-validation
        class="ma-0 pt-6"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="item.name"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Name')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="item.code"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Code')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <v-text-field
                v-model="item.price"
                v-number="number"
                :rules="[validator.required]"
                dense
                outlined
                :label="$t('Price')"
                class="w-full"
                type="text"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="item.description"
                dense
                outlined
                :label="$t('Description')"
                class="w-full"
              >
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ $t('Material') }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                lazy-validation
                class="ma-0 pt-6"
              >
                <v-row class="mb-2 border-bottom-2px-silver">
                  <v-col
                    cols="12"
                    sm="6"
                    md="1"
                  >
                    {{ $t('#') }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    {{ $t('Category') }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    {{ $t('Material') }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="1"
                    class="text-right"
                  >
                    {{ $t('Price') }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="1"
                  >
                    {{ $t('Unit') }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    {{ $t('Quantity') }}
                  </v-col>
                </v-row>
                <v-row
                  v-for="(material, index) in item.materials"
                  :key="index"
                  class="py-2"
                >
                  <v-col
                    cols="12"
                    sm="6"
                    md="1"
                    class="pt-2"
                  >
                    {{ (index + 1) }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-autocomplete
                      v-model="material.stockItemCategoryId"
                      :items="$store.getters.getStockItemCategories"
                      dense
                      outlined
                      :no-data-text="$t('noDataText')"
                      hide-details
                      :label="$t('Category')"
                      item-value="id"
                      item-text="name"
                    >
                      <template #message="{ message }">
                        {{ $t(message) }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-autocomplete
                      :id="`material-item-${index}`"
                      v-model="material.id"
                      :items="filterProductByCategory(material, index)"
                      dense
                      outlined
                      :no-data-text="$t('noDataText')"
                      hide-details
                      item-value="id"
                      item-text="name"
                      @change="generateMaterialItem(material.id, index)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="1"
                    class="pt-2 text-right"
                  >
                    {{ material.price ? (+material.price).toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : material.price }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="1"
                    class="pt-2"
                  >
                    {{ material.unit }}
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <v-text-field
                      v-if="material.unit === 'kom'"
                      :id="`material-quantity-${index}`"
                      v-model="material.quantity"
                      dense
                      outlined
                      hide-details
                      type="number"
                      :rules="[validator.required]"
                    />
                    <v-text-field
                      v-if="material.unit === 'cm2'"
                      v-model="material.multiplier1"
                      dense
                      outlined
                      hide-details
                      type="number"
                      :rules="[validator.required]"
                      :label="material.unit === 'cm2' ? 'Dužina' : ''"
                      class="mt-1"
                    />
                    <v-text-field
                      v-if="material.unit === 'cm2'"
                      v-model="material.multiplier2"
                      dense
                      outlined
                      hide-details
                      type="number"
                      :rules="[validator.required]"
                      :label="material.unit === 'cm2' ? 'Širina' : ''"
                      class="mt-1"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="1"
                  >
                    <v-btn
                      fab
                      x-small
                      color="error"
                      @click.prevent="removeMaterial(item.id, index)"
                    >
                      <v-icon dark>
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="w-full">
                  <v-col
                    class="pt-6"
                    cols="12"
                  >
                    <v-btn
                      fab
                      small
                      color="success"
                      class="mr-1"
                      @click.prevent="addMaterial(item.id)"
                    >
                      <v-icon dark>
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
      <StandardCardHeader
        :go-to-link="'admin-item-list'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close' ) }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCellphoneKey,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEmailAlert,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiMinus,
} from '@mdi/js'
import axiosIns from '@axios'
import { required } from '@core/utils/validation'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'

export default {
  components: { StandardCardHeader },
  mixins: [numberFormatMixin],
  data() {
    const snackbarMessage = ''
    const errorMessages = []
    const itemId = null
    const snackbar = false
    const loading = true
    const item = {
      price: '',
      materials: [{}],
    }
    const materialList = []
    const originalMaterialList = []

    return {
      dateMenu: false,
      snackbarMessage,
      errorMessages,
      itemId,
      snackbar,
      loading,
      item,
      materialList,
      originalMaterialList,
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiCellphoneKey,
        mdiEmailAlert,
        mdiMinus,
      },

      validator: {
        required,
      },
    }
  },
  mounted() {
    this.getMaterials(1)
  },
  methods: {
    getMaterials(location, search = '') {
      axiosIns.get(`/admin/stocks/${location}/items?page=1&perPage=10000&search=${search}`)
        .then(res => {
          this.materialList = res.data.items.data
          this.originalMaterialList = this.materialList
        })
        .catch(() => {})
    },
    generateMaterialItem(materialId, index) {
      const existingMaterial = this.item.materials.find((item, idx) => {
        if (item.id === materialId && index !== idx) {
          document.getElementById(`material-quantity-${idx}`).focus()
        }

        return item.id === materialId && index !== idx
      })
      if (existingMaterial) {
        this.item.materials.splice(index, 1)
      } else {
        const materialItem = this.materialList.find(item => item.id === materialId)
        if (materialItem) {
          const currentItemMaterial = this.item.materials.find((item, idx) => item.id === materialId && index === idx)
          currentItemMaterial.name = materialItem.name
          currentItemMaterial.unit = materialItem.unit
          currentItemMaterial.price = materialItem.price
          currentItemMaterial.quantity = 1
        }
      }
    },
    filterProductByCategory(currentItem) {
      return this.originalMaterialList.filter(m => +m.stock_item_category_id === +currentItem.stockItemCategoryId)
    },
    removeMaterial(itemId, index) {
      this.item.materials.splice(index, 1)
    },
    addMaterial() {
      const materialsLength = this.item.materials.length
      let isLastItemEmpty = false
      if (materialsLength > 0) {
        if (!this.item.materials[materialsLength - 1].id) {
          isLastItemEmpty = true
          document.getElementById(`material-item-${materialsLength - 1}`).focus()
        }
      }
      if (!isLastItemEmpty) {
        this.item.materials.push({})
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        axiosIns({
          method: this.itemId ? 'PUT' : 'POST',
          data: { ...this.item, price: this.item.price.replace(/,/g, '') },
          url: this.itemId ? `/admin/items/${this.itemId}` : '/admin/items',
        })
          .then(res => {
            this.snackbarMessage = this.$t('Data successfully saved')
            this.snackbar = true
            this.itemId = res.data.item.id
            this.$store.dispatch('fetchItems')
          })
          .catch(() => {
            this.snackbarMessage = this.$t('An error occurred')
            this.snackbar = true
          })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
